import { SQLMonarchLanguage, grafanaStandardSQLLanguage, grafanaStandardSQLLanguageConf } from '@grafana/experimental';

// Language based on standard SQL, but edited according to https://docs.aws.amazon.com/redshift/latest/dg/c_SQL_commands.html

export const logicalOperators = [
  'AND',
  'BETWEEN',
  'IN',
  'LIKE',
  'NOT',
  'OR',
  'IS',
  'NULL',
  'INTERSECT',
  'UNION',
  'INNER',
  'JOIN',
  'LEFT',
  'OUTER',
  'RIGHT',
];
export const comparisonOperators = ['<>', '>', '<', '>=', '<=', '=', '!=', '&', '~', '^', '%'];
export const keywords = [
  'AES128',
  'AES256',
  'ALL',
  'ALLOWOVERWRITE',
  'ANALYSE',
  'ANALYZE',
  'AND',
  'ANY',
  'ARRAY',
  'AS',
  'ASC',
  'AUTHORIZATION',
  'AZ64',
  'BACKUP',
  'BETWEEN',
  'BINARY',
  'BLANKSASNULL',
  'BOTH',
  'BY',
  'BYTEDICT',
  'BZIP2',
  'CASE',
  'CAST',
  'CHECK',
  'COLLATE',
  'COLUMN',
  'CONSTRAINT',
  'CREATE',
  'CREDENTIALS',
  'CROSS',
  'CURRENT_DATE',
  'CURRENT_TIME',
  'CURRENT_TIMESTAMP',
  'CURRENT_USER',
  'CURRENT_USER_ID',
  'DEFAULT',
  'DEFERRABLE',
  'DEFLATE',
  'DEFRAG',
  'DELTA',
  'DELTA32K',
  'DESC',
  'DISABLE',
  'DISTINCT',
  'DO',
  'ELSE',
  'EMPTYASNULL',
  'ENABLE',
  'ENCODE',
  'ENCRYPT',
  'ENCRYPTION',
  'END',
  'EXCEPT',
  'EXPLICIT',
  'FALSE',
  'FOR',
  'FOREIGN',
  'FREEZE',
  'FROM',
  'FULL',
  'GLOBALDICT256',
  'GLOBALDICT64K',
  'GRANT',
  'GROUP',
  'GZIP',
  'HAVING',
  'IDENTITY',
  'IGNORE',
  'ILIKE',
  'IN',
  'INITIALLY',
  'INNER',
  'INTERSECT',
  'INTO',
  'IS',
  'ISNULL',
  'JOIN',
  'LANGUAGE',
  'LEADING',
  'LEFT',
  'LIKE',
  'LIMIT',
  'LOCALTIME',
  'LOCALTIMESTAMP',
  'LUN',
  'LUNS',
  'LZO',
  'LZOP',
  'MINUS',
  'MOSTLY13',
  'MOSTLY16',
  'MOSTLY32',
  'MOSTLY8',
  'NATURAL',
  'NEW',
  'NOT',
  'NOTNULL',
  'NULL',
  'NULLS',
  'OFF',
  'OFFLINE',
  'OFFSET',
  'OID',
  'OLD',
  'ON',
  'ONLY',
  'OPEN',
  'OR',
  'ORDER',
  'OUTER',
  'OVERLAPS',
  'PARALLEL',
  'PARTITION',
  'PERCENT',
  'PERMISSIONS',
  'PIVOT',
  'PLACING',
  'PRIMARY',
  'RAW',
  'READRATIO',
  'RECOVER',
  'REFERENCES',
  'REJECTLOG',
  'RESORT',
  'RESPECT',
  'RESTORE',
  'RIGHT',
  'SELECT',
  'SESSION_USER',
  'SIMILAR',
  'SNAPSHOT',
  'SOME',
  'SYSDATE',
  'SYSTEM',
  'TABLE',
  'TAG',
  'TDES',
  'TEXT255',
  'TEXT32K',
  'THEN',
  'TIMESTAMP',
  'TO',
  'TOP',
  'TRAILING',
  'TRUE',
  'TRUNCATECOLUMNS',
  'UNION',
  'UNIQUE',
  'UNNEST',
  'UNPIVOT',
  'USER',
  'USING',
  'VERBOSE',
  'WALLET',
  'WHEN',
  'WHERE',
  'WITH',
  'WITHOUT',
];

export const builtinFunctions = [
  //aggregate
  'SUM',
  'AVG',
  'COUNT',
  'MIN',
  'MAX',
  'MEDIAN',
  'ANY_VALUE',
  'PERCENTILE_CONT',
  //compute node only
  'LISTAGG',
  'MEDIAN',
  'PERCENTILE_CONT',
  'PERCENTILE_DISC ',
  'APPROXIMATE PERCENTILE_DISC',

  // array
  'array',
  'array_concat',
  'array_flatten',
  'get_array_length',
  'split_to_array',
  'subarray',
  // varbyte
  'VARBYTE',
  'FROM_HEX',
  'FROM_VARBYTE',
  'GETBIT',
  'TO_HEX',
  'TO_VARBYTE',
  'CONCAT',
  'LEN',
  'LENGTH',
  'OCTET_LENGTH',
  'SUBSTR',
  'SUBSTRING',
  // bitwise
  'BIT_AND',
  'BIT_OR',
  'BOOL_AND',
  'BOOL_OR',
  // date and time
  'ADD_MONTHS',
  'CONVERT_TIMEZONE',
  'CURRENT_DATE',
  'DATE_CMP',
  'DATE_CMP_TIMESTAMP',
  'DATE_CMP_TIMESTAMPTZ',
  'DATEADD',
  'DATEDIFF',
  'DATE_PART',
  'DATE_PART_YEAR',
  'DATE_TRUNC',
  'EXTRACT',
  'GETDATE',
  'INTERVAL_CMP',
  'LAST_DAY',
  'MONTHS_BETWEEN',
  'NEXT_DAY',
  'SYSDATE',
  'TIMEOFDAY',
  'TIMESTAMP_CMP',
  'TIMESTAMP_CMP_DATE',
  'TIMESTAMP_CMP_TIMESTAMPTZ',
  'TIMESTAMPTZ_CMP',
  'TIMESTAMPTZ_CMP_DATE',
  'TIMESTAMPTZ_CMP_TIMESTAMP',
  'TIMEZONE',
  'TO_TIMESTAMP',
  //hash
  'CHECKSUM',
  'farmFingerprint64',
  'FUNC_SHA1',
  'FNV_HASH',
  'MD5',
  'SHA',
  'SHA1',
  'SHA2',
  //hyperlog
  'HLL',
  'HLL_CREATE_SKETCH',
  'HLL_CARDINALITY',
  'HLL_COMBINE',
  'HLL_COMBINE_SKETCHES',
  //json
  'IS_VALID_JSON',
  'IS_VALID_JSON_ARRAY',
  'JSON_ARRAY_LENGTH',
  'JSON_EXTRACT_ARRAY_ELEMENT_TEXT',
  'JSON_EXTRACT_PATH_TEXT',
  'JSON_PARSE',
  'CAN_JSON_PARSE',
  'JSON_SERIALIZE',
  'JSON_SERIALIZE_TO_VARBYTE',
  //spatial
  'AddBBox',
  'DropBBox',
  'GeometryType',
  'ST_AddPoint',
  'ST_Angle',
  'ST_Area',
  'ST_AsBinary',
  'ST_AsEWKB',
  'ST_AsEWKT',
  'ST_AsGeoJSON',
  'ST_AsHexWKB',
  'ST_AsHexEWKB',
  'ST_AsText',
  'ST_Azimuth',
  'ST_Boundary',
  'ST_Buffer',
  'ST_Centroid',
  'ST_Collect',
  'ST_Contains',
  'ST_ContainsProperly',
  'ST_ConvexHull',
  'ST_CoveredBy',
  'ST_Covers',
  'ST_Crosses',
  'ST_Dimension',
  'ST_Disjoint',
  'ST_Distance',
  'ST_DistanceSphere',
  'ST_DWithin',
  'ST_EndPoint',
  'ST_Envelope',
  'ST_Equals',
  'ST_ExteriorRing',
  'ST_Force2D',
  'ST_Force3D',
  'ST_Force3DM',
  'ST_Force3DZ',
  'ST_Force4D',
  'ST_GeoHash',
  'ST_GeogFromText',
  'ST_GeogFromWKB',
  'ST_GeometryN',
  'ST_GeometryType',
  'ST_GeomFromEWKB',
  'ST_GeomFromEWKT',
  'ST_GeomFromText',
  'ST_GeomFromWKB',
  'ST_InteriorRingN',
  'ST_Intersects',
  'ST_Intersection',
  'ST_IsPolygonCCW',
  'ST_IsPolygonCW',
  'ST_IsClosed',
  'ST_IsCollection',
  'ST_IsEmpty',
  'ST_IsRing',
  'ST_IsSimple',
  'ST_IsValid',
  'ST_Length',
  'ST_LengthSphere',
  'ST_Length2D',
  'ST_LineFromMultiPoint',
  'ST_LineInterpolatePoint',
  'ST_M',
  'ST_MakeEnvelope',
  'ST_MakeLine',
  'ST_MakePoint',
  'ST_MakePolygon',
  'ST_MemSize',
  'ST_MMax',
  'ST_MMin',
  'ST_Multi',
  'ST_NDims',
  'ST_NPoints',
  'ST_NRings',
  'ST_NumGeometries',
  'ST_NumInteriorRings',
  'ST_NumPoints',
  'ST_Perimeter',
  'ST_Perimeter2D',
  'ST_Point',
  'ST_PointN',
  'ST_Points',
  'ST_Polygon',
  'ST_RemovePoint',
  'ST_Reverse',
  'ST_SetPoint',
  'ST_SetSRID',
  'ST_Simplify',
  'ST_SRID',
  'ST_StartPoint',
  'ST_Touches',
  'ST_Transform',
  'ST_Union',
  'ST_Within',
  'ST_X',
  'ST_XMax',
  'ST_XMin',
  'ST_Y',
  'ST_YMax',
  'ST_YMin',
  'ST_Z',
  'ST_ZMax',
  'ST_ZMin',
  'SupportsBBox',
  // string
  'ASCII',
  'BPCHARCMP',
  'BTRIM',
  'BTTEXT_PATTERN_CMP',
  'CHAR_LENGTH',
  'CHARACTER_LENGTH',
  'CHARINDEX',
  'CHR',
  'COLLATE',
  'CONCAT',
  'CRC32',
  'DIFFERENCE',
  'INITCAP',
  'LEFT',
  'RIGHT',
  'LEN',
  'LENGTH',
  'LOWER',
  'LPAD',
  'RPAD',
  'LTRIM',
  'OCTETINDEX',
  'OCTET_LENGTH',
  'POSITION',
  'QUOTE_IDENT',
  'QUOTE_LITERAL',
  'REGEXP_COUNT',
  'REGEXP_INSTR',
  'REGEXP_REPLACE',
  'REGEXP_SUBSTR',
  'REPEAT',
  'REPLACE',
  'REPLICATE',
  'REVERSE',
  'RTRIM',
  'SOUNDEX',
  'SPLIT_PART',
  'STRPOS',
  'STRTOL',
  'SUBSTR',
  'SUBSTRING',
  'TEXTLEN',
  'TRANSLATE',
  'TRIM',
  'UPPER',
  // super type
  'decimal_precision',
  'decimal_scale',
  'is_array',
  'is_bigint',
  'is_boolean',
  'is_char',
  'is_decimal',
  'is_float',
  'is_integer',
  'is_object',
  'is_scalar',
  'is_smallint',
  'is_varchar',
  'json_typeof',
  // datatype formatting
  'TO_CHAR',
  'TO_DATE',
  'TO_NUMBER',
  'TEXT_TO_INT_ALT',
  'TEXT_TO_NUMERIC_ALT',
  // system
  'CHANGE_QUERY_PRIORITY',
  'CHANGE_SESSION_PRIORITY',
  'CHANGE_USER_PRIORITY',
  'CURRENT_SETTING',
  'PG_CANCEL_BACKEND',
  'PG_TERMINATE_BACKEND',
  'REBOOT_CLUSTER',
  'SET_CONFIG',
  'CURRENT_AWS_ACCOUNT',
  'CURRENT_DATABASE',
  'CURRENT_NAMESPACE',
  'CURRENT_SCHEMA',
  'CURRENT_SCHEMAS',
  'CURRENT_USER',
  'CURRENT_USER_ID',
  'DEFAULT_IAM_ROLE',
  'HAS_ASSUMEROLE_PRIVILEGE',
  'HAS_DATABASE_PRIVILEGE',
  'HAS_SCHEMA_PRIVILEGE',
  'HAS_TABLE_PRIVILEGE',
  'PG_BACKEND_PID',
  'PG_GET_COLS',
  'PG_GET_GRANTEE_BY_IAM_ROLE',
  'PG_GET_IAM_ROLE_BY_USER',
  'PG_GET_LATE_BINDING_VIEW_COLS',
  'PG_LAST_COPY_COUNT',
  'PG_LAST_COPY_ID',
  'PG_LAST_UNLOAD_ID',
  'PG_LAST_QUERY_ID',
  'PG_LAST_UNLOAD_COUNT',
  'SLICE_NUM',
  'USER',
  'ROLE_IS_MEMBER_OF',
  'USER_IS_MEMBER_OF',
  'VERSION',
];

export const language: SQLMonarchLanguage = {
  ...grafanaStandardSQLLanguage,
  keywords,
  comparisonOperators,
  logicalOperators,
  builtinFunctions,
};

export const conf = grafanaStandardSQLLanguageConf;
